import DateFnsUtils from "@date-io/date-fns";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import { saveAs } from "file-saver";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from 'date-fns/locale';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from "react-select";
import {
    Button,
    Dimmer,
    Icon,
    Loader
} from "semantic-ui-react";
import api from '../../api';
import { setModalIntegration } from "../../redux/modal";
import { TypeRole } from "../../enums";

const _EXPORT_EXCEL_FILE = 'excel';
const _EXPORT_TXT_FILE = 'file';
export default function ModalIntegration({setDataToast}){
    const dispatch = useDispatch();
    const modalIntegration = useSelector((state) => state.modal.integrationModal);
    const [values, setValues] = React.useState({periodo: null, obraSocialId: null});
    const [socialWorkList, setSocialWorkList] = React.useState([]);
    const [loadingActive, setLoadingActive] = React.useState(false);

    React.useEffect(()=>{
        const TypeRoleLogged = JSON.parse(localStorage.getItem("TypeRole"));
        if (TypeRoleLogged === TypeRole.Admin)  GetSocialWorks();
    },[]);

    function GetSocialWorks(){
        api.GetSocialWorkForSelect()
        .then(resp=>{
            setSocialWorkList(resp.data);
        })
        .catch(err=> {
            MessageNotification(err.response.data.errores, "error");
        })
    }
    function OnClickExport(action){
        if (values.periodo === null || values.obraSocialId === null){
            MessageNotification("El campo 'Mes - Año' y 'Obra Social' son obligatorios.", "error");
            return;
        }
        
        setLoadingActive(true);
        const params = {
            mes: values.periodo.getMonth() + 1,
            anio: values.periodo.getFullYear(),
            obraSocialId: values.obraSocialId,
        }
        if (action === _EXPORT_EXCEL_FILE){
            api.exportIntegrationExcelFile(params)
            .then(res=>{
                const extension = 'xlsx';
                const tempFileName =
                'Período' +
                params.mes +
                '-' +
                params.anio;
                const fileName = `${tempFileName}.${extension}`;
                
                const blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel',
                })
                saveAs(blob, fileName);
                MessageNotification("Archivo exportado con éxito.", "success");
            })
            .catch((err) => {MessageNotification(err?.response?.data?.errores, 'error');})
            .finally(() => setLoadingActive(false));
        }
        else if(action === _EXPORT_TXT_FILE){
            api.exportIntegrationTxtFile(params)
            .then(res=> {
                const extension = 'txt';
                const tempFileName =
                'Período' +
                params.mes +
                '-' +
                params.anio;
                const fileName = `${tempFileName}.${extension}`;
                
                const blob = new Blob([res.data], {
                    type: 'text/plain',
                })
                saveAs(blob, fileName);
                MessageNotification("Archivo exportado con éxito.", "success");
            })
            .catch((err) => {MessageNotification(err?.response?.data?.errores, 'error');})
            .finally(() => setLoadingActive(false));
        }
    }

    //#region  UTILS
    function MessageNotification(message, type){
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error.",
            type: type,
        });
    }
    function HandleCloseModal(){
        dispatch(setModalIntegration(false));
        setValues({periodo: null, obraSocialId: null});
    }
    //#endregion
    return(<>

        <Dialog
        open={modalIntegration}
        onClose={HandleCloseModal}
        aria-labelledby='form-dialog-title'
    >
        {loadingActive &&
        <Dimmer className='loader-fixed' active inverted>
            <Loader size='big'>Cargando...</Loader>
        </Dimmer>}

        <DialogTitle id='form-dialog-title'>
            Exportar
        </DialogTitle>
        <DialogContent style={{overflowY: "visible"}} className="cont-default-modal">
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
            <>
                <KeyboardDatePicker
                    style={{ marginRight: 5, marginTop: 5 }}
                    autoOk
                    className='size-date-picke-filter'
                    inputVariant='outlined'
                    InputAdornmentProps={{ position: 'end' }}
                    variant='inline'
                    name='month'
                    openTo='month'
                    views={['month', 'year']}
                    placeholder='Mes - Año'
                    value={values.periodo}
                    onChange={(date) => setValues({...values, periodo: date})}
                />
            </>
            </MuiPickersUtilsProvider>
            <ReactSelect
                placeholder='Obra Social...'
                options={socialWorkList}
                getOptionLabel={(option) => option.nombre}
                getOptionValue={(option) => option.id}
                onChange={(e)=>setValues({...values, obraSocialId: e.id})}
                styles={{
                    control: (s)=>({...s, maxWidth: 300})
                }}
            />
        </DialogContent>
        <DialogActions style={{justifyContent: "center"}}>
            <Button onClick={HandleCloseModal}>
                Cancelar
            </Button>
            <Button onClick={() => OnClickExport(_EXPORT_EXCEL_FILE)} color='blue'>
                Excel
            </Button>
             <Button onClick={() => OnClickExport(_EXPORT_TXT_FILE)} color='blue'>
                Text
            </Button>
        </DialogActions>
        </Dialog>
    </>);
}