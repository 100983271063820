import React from 'react';
import ReactSelect from 'react-select';
import { Collapse } from "@material-ui/core";

const CollapseSelect = ({ values, setValues, socialWorkList, closed }) => {
    return (
        <Collapse in={values.origenDatos === 4} timeout={300}>
            {!closed && 
                <ReactSelect
                    placeholder='Obra Social...'
                    options={socialWorkList}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => setValues({ ...values, obraSocial: { id: e.id, name: e.nombre } })}
                    styles={{
                        control: (s) => ({ ...s, maxWidth: 300 })
                    }}
                    maxMenuHeight={200}
                />
            }
        </Collapse>
    );
};

export default CollapseSelect;