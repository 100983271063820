import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from "react-select";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import api from '../../api';
import { setModalSocialWorkWithholdings } from "../../redux/modal";
import { StatusCode, TypeRole } from "../../enums";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import { GetSocialWorkWithholdings } from "../../services/services/Reports";
import Toast from "../common/Toast";

const OrderByList = [
    { value: 1, label: "Ninguno" },
    { value: 2, label: "Matrícula" },
    { value: 3, label: "Nombre" },
    { value: 4, label: "Monto" }
]
const defaultValues = {
    obraSocial: {id: null, name: ''},
    ordenarPor: 1,
    esAscendente: true
}

export default function SocialWorkWithholdingsModal() {
    const [dataToast, setDataToast] = React.useState({
        active: false,
        message: '',
        type: '',
    });
    const dispatch = useDispatch();
    const modalWithholdings = useSelector((state) => state.modal.socialWorkWithholdingsModal);
    const [values, setValues] = React.useState(defaultValues);
    const [socialWorkList, setSocialWorkList] = React.useState([]);
    const [loadingActive, setLoadingActive] = React.useState(false);

    React.useEffect(() => {
        const TypeRoleLogged = JSON.parse(localStorage.getItem("TypeRole"));
        if (TypeRoleLogged === TypeRole.Admin) GetSocialWorks();
    }, []);

    const handleOrderChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setValues((prevValues) => ({
            ...prevValues,
            ordenarPor: newValue,
            // Si se selecciona "Ninguno", restablecer esAscendente
            esAscendente: newValue === 1 ? true : prevValues.esAscendente
        }));
    };

    const handleDirectionChange = (event) => {
        const esAscendente = event.target.value === 'ascendente';
        setValues((prevValues) => ({
            ...prevValues,
            esAscendente
        }));
    };

    function GetSocialWorks() {
        api.GetSocialWorkForSelect()
            .then(resp => {
                setSocialWorkList(resp.data);
            })
            .catch(err => {
                MessageNotification(err.response.data.errores, "error");
            })
    }

    function OnClickGenerate() {
        if (values.obraSocial.id === null) {
            MessageNotification("El campo 'Obra Social' es obligatorio.", "error");
            return;
        }

        const params = {
            socialWorkId: values.obraSocial.id,
            orderBy: values.ordenarPor === 1 ? null : values.ordenarPor - 1,
            isAscendent: values.esAscendente,
        }
        
        HandleSocialWorkWithholdings(params);
    }

    async function HandleSocialWorkWithholdings(params) {
        try {
            setLoadingActive(true);
            const { data, status } = await GetSocialWorkWithholdings(params);
            if (status === StatusCode.Ok) {
                UtilityFNExport("pdf", `Retenciones de Obra Social ${values.obraSocial.name}`, data.results);
            }
        } catch (err) {
            const errorMessage = err.message || 'No se pudo obtener el PDF.';
            MessageNotification(errorMessage, "error");
        } finally {
            setLoadingActive(false);
        }
    }

    //#region  UTILS
    function UtilityFNExport(extension, nameOfFile, responseApi) {
        let fileName = `${nameOfFile}.${extension}`;
        let blob = new Blob([responseApi], {
            type: "application/pdf",
        });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    function MessageNotification(message, type) {
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error.",
            type: type,
        });
    }
    function HandleCloseModal() {
        dispatch(setModalSocialWorkWithholdings(false));
        setValues(defaultValues);
    }
    //#endregion
    return (<>

        <Dialog
            open={modalWithholdings}
            onClose={HandleCloseModal}
            aria-labelledby='form-dialog-title'
        >
            {loadingActive &&
                <Dimmer className='loader-fixed' active inverted>
                    <Loader size='big'>Cargando...</Loader>
                </Dimmer>}

            <DialogTitle id='form-dialog-title'>
                Retenciones Obra Social Profesional
            </DialogTitle>
            <DialogContent style={{ overflowY: "visible" }} className="cont-default-modal">
                {/* Sección de "Obras Sociales" */}
                <ReactSelect
                    placeholder='Obra Social...'
                    options={socialWorkList}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => setValues({ ...values, obraSocial: {id: e.id, name: e.nombre} })}
                    styles={{
                        control: (s) => ({ ...s, maxWidth: 300 })
                    }}
                />

                {/* Sección de "Orden" */}
                <FormControl component="fieldset" style={{ maxWidth: "300px" }}>
                    <FormLabel component="legend">Orden</FormLabel>
                    <RadioGroup
                        aria-label="orden"
                        row
                        name="ordenNinguno"
                        value={values.ordenarPor.toString()}
                        onChange={handleOrderChange}
                        style={{ justifyContent: "space-evenly" }}
                    >
                        {OrderByList.map((order) => (
                            <FormControlLabel
                                key={order.value}
                                value={order.value.toString()}
                                control={<Radio />}
                                label={order.label}
                                style={{ width: "115px" }}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>

                {/* Sección de "Dirección" */}
                <FormControl component="fieldset">
                    <FormLabel component="legend">Dirección</FormLabel>
                    <RadioGroup
                        aria-label="direccion"
                        name="direccion"
                        value={values.esAscendente ? 'ascendente' : 'descendente'}
                        onChange={handleDirectionChange}
                        row
                        style={{ justifyContent: "space-evenly" }}
                    >
                        <FormControlLabel
                            value="ascendente"
                            control={<Radio />}
                            label="Ascendente"
                            disabled={values.ordenarPor === 1}
                        />
                        <FormControlLabel
                            value="descendente"
                            control={<Radio />}
                            label="Descendente"
                            disabled={values.ordenarPor === 1}
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button onClick={HandleCloseModal}>
                    Cancelar
                </Button>
                <Button onClick={() => OnClickGenerate()} color='blue'>
                    Generar
                </Button>
            </DialogActions>
            {dataToast.active && (
                <Toast dataToast={dataToast} setDataToast={setDataToast} />
            )}
        </Dialog>
    </>);
}