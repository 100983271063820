import React from 'react'
import { ISideBarItem } from '../../services/interfaces/SideBarItem/ISideBarItem'
import { Tooltip, makeStyles } from '@material-ui/core'
import { Link } from "react-router-dom"

const useStyles = makeStyles(()=>({
    containerSideBarItems: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: 10,
        margin: "0px 0",
        position:"relative"
    }
}));
const buttonSelected = (textUrl: string) => {
    let url = window.location.href;
    if (url.includes(textUrl)) {
        return true;
    } else {
        return false;
    }
};
const SideBarItem: React.FC<ISideBarItem> = ({
    urlToRedirect, titleTooltip, children, dispatchAction
})=>{
    const classStyle = useStyles();
    return(
        <Link to={urlToRedirect} onClick={dispatchAction} className={
            buttonSelected(urlToRedirect)
                ? `${classStyle.containerSideBarItems} icon-sidebar-selected`
                : `${classStyle.containerSideBarItems} icon-sidebar`
        }>
            <Tooltip title={titleTooltip}>
                {children}
            </Tooltip>
        </Link>
    )
}

export default SideBarItem;