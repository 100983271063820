import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer } = createSlice({
    name: 'modal',
    initialState: {
        integrationModal: false,
        socialWorkWithholdingsModal: false,
        unpaidBillsModal: false,
    },
    reducers: {
        setModalIntegration(state, { payload }) {
            state.integrationModal = payload
        },
        setModalSocialWorkWithholdings(state, { payload }) {
            state.socialWorkWithholdingsModal = payload
        },
        setModalUnpaidBills(state, { payload }) {
            state.unpaidBillsModal = payload
        },
    }
});

export const { setModalIntegration, setModalSocialWorkWithholdings, setModalUnpaidBills } = actions;
export default reducer;