import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dimmer, Loader } from "semantic-ui-react";
import api from '../../api';
import { setModalUnpaidBills } from "../../redux/modal";
import { RadioDataSource, RadioDataSourceEnum, StatusCode, TypeRole } from "../../enums";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import { GetUnpaidBills } from "../../services/services/Reports";
import Toast from "../common/Toast";
import CollapseSelect from "./CollapseSelect";

const defaultValues = {
    obraSocial: { id: null, name: '' },
    origenDatos: RadioDataSourceEnum.TodasLasObrasSociales,
    edadFactura: 0
}

export default function UnpaidBillsModal() {
    const [dataToast, setDataToast] = React.useState({
        active: false,
        message: '',
        type: '',
    });
    const dispatch = useDispatch();
    const modalWithholdings = useSelector((state) => state.modal.unpaidBillsModal);
    const [values, setValues] = React.useState(defaultValues);
    const [socialWorkList, setSocialWorkList] = React.useState([]);
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [closed, setClosed] = React.useState(false);

    React.useEffect(() => {
        const TypeRoleLogged = JSON.parse(localStorage.getItem("TypeRole"));
        if (TypeRoleLogged === TypeRole.Admin) GetSocialWorks();
    }, []);

    React.useEffect(() => {
        if (values.origenDatos !== 4) {
            setTimeout(() => {
                setClosed(true);
            }, 100);
        }
        else {
            setClosed(false);
        }
    }, [values.origenDatos]);

    function GetSocialWorks() {
        api.GetSocialWorkForSelect()
            .then(resp => {
                setSocialWorkList(resp.data);
            })
            .catch(err => {
                MessageNotification(err.response.data.errores, "error");
            })
    }
    const handleDataSource = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setValues((prevValues) => ({
            ...prevValues,
            origenDatos: newValue,
            // Si se selecciona uno distinto a "Sólo una Obra Social", restablecer obraSocial al valor por defecto
            obraSocial: newValue !== RadioDataSourceEnum.SoloUnaObraSocial ? defaultValues.obraSocial : prevValues.obraSocial
        }));
    };
    const handleEdadFacturaChange = (event) => {
        const edadFactura = event.target.value;
        setValues((prevValues) => ({
            ...prevValues,
            edadFactura: edadFactura
        }));
    }

    function OnClickGenerate() {
        if (values.origenDatos === RadioDataSourceEnum.SoloUnaObraSocial && values.obraSocial.id === null) {
            MessageNotification("El campo 'Obra Social' es obligatorio.", "error");
            return;
        }
        if(values.edadFactura < 0) {
            MessageNotification("El campo 'Edad de la Factura' no puede ser negativo.", "error");
            return;
        }
        const params = {
            socialWorkId: values.obraSocial.id,
            dataSource: values.origenDatos,
            billAge: values.edadFactura,
        }

        HandleUnpaidBills(params);
    }

    async function HandleUnpaidBills(params) {
        try {
            setLoadingActive(true);
            const { data, status } = await GetUnpaidBills(params);
            if (status === StatusCode.Ok) {
                let tittleReport = 'Facturas impagas';
                if (values.origenDatos === RadioDataSourceEnum.ConConvenio) {
                    tittleReport += ` (Con convenio)`;
                }
                if (values.origenDatos === RadioDataSourceEnum.SinConvenioDirecto) {
                    tittleReport += ` (Sin convenio)`;
                }
                if (values.origenDatos === RadioDataSourceEnum.SoloUnaObraSocial) {
                    tittleReport += ` (${values.obraSocial.name})`;
                }
                UtilityFNExport("pdf", tittleReport, data.results);
            }
        } catch (err) {
            const errorMessage = err.message || 'No se pudo obtener el PDF.';
            MessageNotification(errorMessage, "error");
        } finally {
            setLoadingActive(false);
        }
    }
    //#region  UTILS
    function UtilityFNExport(extension, nameOfFile, responseApi) {
        let fileName = `${nameOfFile}.${extension}`;
        let blob = new Blob([responseApi], {
            type: "application/pdf",
        });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    function MessageNotification(message, type) {
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error.",
            type: type,
        });
    }
    function HandleCloseModal() {
        dispatch(setModalUnpaidBills(false));
        setValues(defaultValues);
    }
    //#endregion
    return (
        <Dialog
            open={modalWithholdings}
            onClose={HandleCloseModal}
            aria-labelledby='form-dialog-title'
        >
            {loadingActive &&
                <Dimmer className='loader-fixed' active inverted>
                    <Loader size='big'>Cargando...</Loader>
                </Dimmer>}

            <DialogTitle id='form-dialog-title' style={{ textAlign: "center" }}>
                Facturas Impagas
            </DialogTitle>
            <DialogContent style={{ overflowY: "visible" }} className="cont-default-modal">
                <FormControl component="fieldset" style={{ maxWidth: "300px" }}>
                    <FormLabel component="legend">Origen de los datos</FormLabel>
                    <RadioGroup
                        aria-label="origen de datos"
                        row
                        name="origenDatos"
                        value={values.origenDatos.toString()}
                        onChange={handleDataSource}
                    >
                        {Object.keys(RadioDataSourceEnum).map((key) => {
                            const enumValue = RadioDataSourceEnum[key];
                            return (
                                <FormControlLabel
                                    key={enumValue}
                                    value={enumValue.toString()}
                                    control={<Radio />}
                                    label={RadioDataSource[enumValue]}
                                    style={{ marginLeft: "10px" }}
                                />
                            );
                        })}
                    </RadioGroup>

                    <CollapseSelect
                        values={values}
                        setValues={setValues}
                        socialWorkList={socialWorkList}
                        closed={closed}
                    />

                    <div style={{ marginTop: "15px" }}>
                        <FormLabel>Edad de la factura</FormLabel>
                        <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
                            <TextField
                                type="number"
                                value={values.edadFactura}
                                onChange={handleEdadFacturaChange}
                                inputProps={{ min: 0 }}
                                style={{ maxWidth: "50px", marginRight: "10px" }}
                            />
                            <span>meses</span>
                        </div>
                    </div>
                </FormControl>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button onClick={HandleCloseModal}>
                    Cancelar
                </Button>
                <Button onClick={() => OnClickGenerate()} color='blue'>
                    Generar
                </Button>
            </DialogActions>
            {dataToast.active && (
                <Toast dataToast={dataToast} setDataToast={setDataToast} />
            )}
        </Dialog>
    );
}