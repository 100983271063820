import { useState } from "react";
import { Provider } from "react-redux";
import React from 'react';
import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch,
} from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import SocialWork from "./components/Documents/Document";
import IntegrationLIst from "./components/Integrations/IntegrationList";
import PrivateRoute from "./components/common/PrivateRoute";
import Toast from "./components/common/Toast";
import Account from "./components/configurations/Account";
import AccountList from "./components/configurations/AccountList";
import CrudAssociation from './components/configurations/Associations/CrudAssociation';
import ExpExcel from "./components/configurations/Excel/SettingExcel";
import Module from "./components/configurations/Module";
import News from "./components/configurations/News/News";
import { ViewNewsList } from "./components/configurations/News/ViewNewsList";
import CrudPeriod from "./components/configurations/Period/CrudPeriod";
import PracticesForOs from "./components/configurations/Practices/PracticesForOs";
import CrudRegional from "./components/configurations/Regional/CrudRegional";
import OsForRegional from './components/configurations/SocialWork/OsForRegional';
import PlanForOs from "./components/configurations/SocialWork/SupAdmin-SocWork/PlanForOs";
import Amount from "./components/configurations/SocialWork/amount/Amount";
import AmountHistorial from "./components/configurations/SocialWork/amount/AmountHistorial";
import InstructiveList from "./components/configurations/SocialWork/instructive/InstructiveList";
import ConfirmOsForAssociation from "./components/configurations/SocialWork/modals/ConfirmOsForAssociation";
import AssignPracticeForOs from "./components/configurations/SocialWork/practices/AssignPracticeForOs";
import AccountSummary from "./components/configurations/Users/AccountSummary";
import Users from "./components/configurations/Users/Users";
import UsersCreateOrUpdate from "./components/configurations/Users/UsersCreateOrUpdate";
import Dashboard from "./components/dashboard/Dashboard";
import Document from "./components/document";
import DocumentZipList from "./components/document/DocumentsZipList";
import ImportFile from "./components/document/importFile";
import Holidays from "./components/holidays/Holidays";
import OptionList from "./components/indicators/OptionList";
import Inicio from "./components/landingPage/Inicio";
import LandingOctus from "./components/landingPage/LandingOctus";
import Liquidation from "./components/liquidation/Liquidation";
import Login from "./components/login/Login";
import ConfigurationParamsRegional from "./components/managment/ConfigurationParamsRegional";
import CrudAccreditation from "./components/managment/CrudAccreditation";
import CrudBank from "./components/managment/CrudBank";
import CrudBill from "./components/managment/CrudBill";
import CrudDescount from "./components/managment/CrudDescount";
import CrudInstallment from "./components/managment/CrudInstallment";
import ProfitWithHoldings from "./components/managment/ProfitWithHoldings";
import Receipts from "./components/managment/Receipts";
import EditLiquidation from "./components/managment/liquidation/EditLiquidation";
import NewLiquidation from "./components/managment/liquidation/NewLiquidation";
import SummaryLiquidation from "./components/managment/liquidation/SummaryLiquidation";
import ManualDebitByBill from "./components/managment/panelSection/liquidation/ManualDebitByBill";
import MyAccount from "./components/my-account/MyAccount";
import Period from "./components/period";
import PeriodNewEdit from "./components/period/PeriodNewEdit";
import Returns from "./components/returns/Returns";
import Sidebar from "./components/sideBar/Sidebar";
import "./mediasQueries/mediaQueries.css";
import store from "./redux";
import PreAuthorization from "./components/document/preAuthorization/PreAuthorization";


export default function App() {
	const [dataToast, setDataToast] = useState({
		active: false,
		message: '',
		type: '',
	});
	const [viewSideBar, setViewSideBar] = useState(true);

	return (
		<>
			<Provider store={store}>
				<Router>
					<Switch>
						<Route exact path='/' render={() => <LandingOctus />} />

						<Route path='/login' render={() => <Login />} />

						<Route exact path='/versiones' render={() => <Inicio />} />

						<PrivateRoute path="/perfil" exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								setDataToast={setDataToast}
								Component={MyAccount}
							/>
						</PrivateRoute>

						<PrivateRoute path='/Inicio' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								dataToast={dataToast}
								setDataToast={setDataToast}
								Component={OptionList}
							/>
						</PrivateRoute>

						<Route
							exact
							path='/pacientes'
							render={() => <Redirect to='/pacientes/lista' />}
						/>
						<PrivateRoute path='/pacientes/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Dashboard}
								dataToast={dataToast}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<Route
							exact
							path='/periodos'
							render={() => <Redirect to='/periodos/lista' />}
						/>

						<PrivateRoute path='/paciente/crear' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								setDataToast={setDataToast}
								Component={Document}
							/>
						</PrivateRoute>
						<PrivateRoute path='/paciente/preautorizar/crear' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								setDataToast={setDataToast}
								Component={PreAuthorization}
							/>
						</PrivateRoute>
						<PrivateRoute path='/paciente/:id/preautorizado/ver' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								setDataToast={setDataToast}
								Component={PreAuthorization}
							/>
						</PrivateRoute>
						<PrivateRoute path='/paciente/ver/:id' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								setDataToast={setDataToast}
								Component={Document}
							/>
						</PrivateRoute>
						<PrivateRoute path='/paciente/editar/:id' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								setDataToast={setDataToast}
								Component={Document}
							/>
						</PrivateRoute>
						<PrivateRoute path='/paciente/importar/:id' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								setDataToast={setDataToast}
								Component={ImportFile}
							/>
						</PrivateRoute>
						<PrivateRoute path='/periodos/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Period}
								dataToast={dataToast}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/liquidaciones/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Liquidation}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/liquidaciones' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={NewLiquidation}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/liquidaciones/:id' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={EditLiquidation}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/liquidaciones/facturas/:id/debitos' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={ManualDebitByBill}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/liquidaciones/:id/resumenes' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={SummaryLiquidation}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/instructivos/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={InstructiveList}
								dataToast={dataToast}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/montos/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={AmountHistorial}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/periodo/editar/:id' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								dataToast={dataToast}
								setDataToast={setDataToast}
								Component={PeriodNewEdit}
							/>
						</PrivateRoute>
						<PrivateRoute path='/periodos/presentar' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								dataToast={dataToast}
								setDataToast={setDataToast}
								Component={PeriodNewEdit}
							/>
						</PrivateRoute>
						<PrivateRoute path='/devoluciones/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								dataToast={dataToast}
								setDataToast={setDataToast}
								Component={Returns}
							/>
						</PrivateRoute>

						<Route
							exact
							path='/feriados'
							render={() => <Redirect to='/feriados/lista' />}
						/>

						<PrivateRoute path='/feriados/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Holidays}
								dataToast={dataToast}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<Route
							exact
							path='/documentos'
							render={() => <Redirect to='/documentos/lista' />}
						/>
						<PrivateRoute path='/documentos/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={SocialWork}
								dataToast={dataToast}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/descuentos/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={CrudDescount}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/cuotas/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={CrudInstallment}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/acreditaciones/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={CrudAccreditation}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/facturas/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={CrudBill}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/recibos/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Receipts}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/documentos-por-periodo/listaZip' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={DocumentZipList}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/integraciones/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={IntegrationLIst}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<Route
							exact
							path='/configuraciones'
							render={() => <Redirect to='/configuraciones/modulo' />}
						/>

						<PrivateRoute path='/configuraciones/modulo' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Module}
								dataToast={dataToast}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/cuenta' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Account}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/usuarios' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Users}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/usuarios/crear' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={UsersCreateOrUpdate}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
						<PrivateRoute path='/configuraciones/usuarios/:idProf' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={UsersCreateOrUpdate}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/colegios/listado' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={CrudAssociation}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/períodos/listado' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={CrudPeriod}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/regionales/listado' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={CrudRegional}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/exportar-excel' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={ExpExcel}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/obras-sociales/asignar' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={OsForRegional}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/obras-sociales/confirmar' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={ConfirmOsForAssociation}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/obras-sociales/practicas' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={PracticesForOs}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/obras-sociales/:idOs/planes' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={PlanForOs}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/obras-sociales/:idOs/practicas' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={AssignPracticeForOs}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/obras-sociales/:idOs/montos' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={Amount}
								setDataToast={setDataToast}
								dataToast={dataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/regional' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={ConfigurationParamsRegional}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/retenciones' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={ProfitWithHoldings}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/bancos' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={CrudBank}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/novedades' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={News}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/novedades/lista' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={ViewNewsList}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/cuentas/usuarios/:id' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={AccountList}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>

						<PrivateRoute path='/configuraciones/usuarios/:id/resumen-de-cuenta' exact>
							<Sidebar
								viewSideBar={viewSideBar}
								setViewSideBar={setViewSideBar}
								Component={AccountSummary}
								setDataToast={setDataToast}
							/>
						</PrivateRoute>
					</Switch>
				</Router>
			</Provider>
			{dataToast.active && (
				<Toast dataToast={dataToast} setDataToast={setDataToast} />
			)}
		</>
	);
}
