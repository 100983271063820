import DateFnsUtils from "@date-io/date-fns";
import { Card, Grid, TextField } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { es } from 'date-fns/locale';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { ISummaryLiquidation, ITotalNumberSocialWork, ITotalNumbersProfessional } from "../../../services/interfaces/Managment/ISummaryLiquidation";
import { FormatNumber } from "../../utils/FormatInputs";
import GeneralModalTabs from "../../utils/GeneralModalTabs";
import SummaryForProfessional from "../panelSection/liquidation/SummaryForProfessional";
import SummaryForSocialWork from "../panelSection/liquidation/SummaryForSocialWork";
import { ExportReportProfitRetention, ExportReportSocialWorkPeriod, ExportSummaryBalanceReport, ExportSummaryLiquidationReport, ExportSummaryProfessionalReport, ExportSummarySocialWorksReport, FinalizeLiquidation, GetLiquidationDetails, GetLiquidationDetailsForProfessional, GetLiquidationSummaryForProfessional, GetLiquidationSummaryForSocialWork } from "../../../services/services/Liquidation";
import { StateOfLiquidation, StatusCode } from "../../../enums";
import { IProfessionalBullDetailsResponse, ISummaryForLiquidationDetails, ISummaryForProfessionalResponse, ISummaryForSocialWorkResponse } from "../../../api/Interfaces/managment/ILiquidation";
import { setDataLiquidation, setRestoreAmounts, setRestoreStateLiquidation } from "../../../redux/generateLiquidation";
import ViewProfesionalBillDetails from "../modals/Liquidation/ViewProfesionalBillDetails";
import { IApiResponse } from "../../../api/Interfaces/IApiResponse";
import { green, red } from "@material-ui/core/colors";

const SummaryLiquidation: React.FC<ISummaryLiquidation> = ({ setDataToast, viewSideBar }) => {
    const history = useHistory();
    const disparador = useDispatch();
    const [sectionSelected, setSectionSelected] = React.useState(0);
    const { id } = useParams<any>();
    const { liquidacion }: any = useSelector<any>(({ generateLiquidation }) => generateLiquidation);
    const [totalNumbersSocialWork, setTotalNumbersSocialWork] = React.useState<ITotalNumberSocialWork>({
        totalFactura: 0,
        totalDebitos: 0,
    });
    const [totalNumbersProfessional, setTotalNumbersProfessional] = React.useState<ITotalNumbersProfessional>({
        totalOSocial: 0,
        totalRetenciones: 0,
        netoAcobrar: 0,
        totalDeudas: 0,
        deudasCanceladas: 0,
        totalSaldos: 0
    });
    const [loadingActive, setLoadingActive] = React.useState(false);

    const initialData: ISummaryForLiquidationDetails = {
        resumenProfesionalLiquidacions: [],
        resumenObraSocialLiquidaciones: []
    };
    const [dataToRenderDetails, setDataToRenderDetails] = React.useState<ISummaryForLiquidationDetails>(initialData);
    const [dataToRenderProfessional, setDataToRenderProfessional] = React.useState<ISummaryForProfessionalResponse[]>([]);
    const [dataToRenderSocialWork, setDataToRenderSocialWork] = React.useState<ISummaryForSocialWorkResponse[]>([]);
    const [showModalProfessionalBillDetails, setShowModalProfessionalBillDetails] = React.useState(false);
    const [dataToRender, setDataToRender] = React.useState<IProfessionalBullDetailsResponse>();

    React.useEffect(() => {
        GetSettlementDetails();
    }, [liquidacion.estado, sectionSelected]);

    async function GetSettlementDetails() {
        try {
            setLoadingActive(true);
            //en caso de que sea borrador se llama al endpoint de GetDetallesLiquidacion
            if (liquidacion.estado === StateOfLiquidation.borrador &&
                dataToRenderDetails.resumenProfesionalLiquidacions.length === 0 &&
                dataToRenderDetails.resumenObraSocialLiquidaciones.length === 0) {
                const { data, status } = await GetLiquidationDetails(id);
                if (status === StatusCode.Ok) {
                    setDataToRenderDetails(data.results);
                    //Totales Profesionales
                    let allTotalsProfessional: ITotalNumbersProfessional = {
                        totalOSocial: 0,
                        totalRetenciones: 0,
                        netoAcobrar: 0,
                        totalDeudas: 0,
                        deudasCanceladas: 0,
                        totalSaldos: 0
                    };
                    data.results.resumenProfesionalLiquidacions.forEach(element => {
                        allTotalsProfessional.totalOSocial += element.totalObraSocial;
                        allTotalsProfessional.totalRetenciones += element.totalRetenciones;
                        allTotalsProfessional.netoAcobrar += element.neto;
                        allTotalsProfessional.totalDeudas += element.deudaSaldoActual < 0 ? element.deudaSaldoActual : 0;
                        allTotalsProfessional.deudasCanceladas += element.deudaCancelada;
                        allTotalsProfessional.totalSaldos += element.deudaSaldoActual > 0 ? element.deudaSaldoActual : 0;
                    });

                    //Totales Obra Social
                    let allTotalsSocialWork: ITotalNumberSocialWork = {
                        totalFactura: 0,
                        totalDebitos: 0,
                    }
                    data.results.resumenObraSocialLiquidaciones.forEach(summaryOs => {
                        allTotalsSocialWork.totalFactura += summaryOs.totalFactura;
                        allTotalsSocialWork.totalDebitos += summaryOs.TotalDebito;
                    });

                    setTotalNumbersSocialWork(allTotalsSocialWork);
                    setTotalNumbersProfessional(allTotalsProfessional);
                }
            }
            //sino, se llama al endpoint de profesionales o obras-sociales correspondientemente
            else if (liquidacion.estado === StateOfLiquidation.generada) {
                //Por Profesional
                if (sectionSelected === 0 && dataToRenderProfessional.length === 0) {
                    setLoadingActive(true);
                    const { data, status } = await GetLiquidationSummaryForProfessional(id);
                    if (status === StatusCode.Ok) {
                        setDataToRenderProfessional(data.results);
                        let allTotals: ITotalNumbersProfessional = {
                            totalOSocial: 0,
                            totalRetenciones: 0,
                            netoAcobrar: 0,
                            totalDeudas: 0,
                            deudasCanceladas: 0,
                            totalSaldos: 0
                        };
                        data.results.forEach(element => {
                            allTotals.totalOSocial += element.totalObraSocial;
                            allTotals.totalRetenciones += element.totalRetenciones;
                            allTotals.netoAcobrar += element.neto;
                            allTotals.totalDeudas += element.deudaSaldoActual < 0 ? element.deudaSaldoActual : 0;
                            allTotals.deudasCanceladas += element.deudaCancelada;
                            allTotals.totalSaldos += element.deudaSaldoActual > 0 ? element.deudaSaldoActual : 0;
                        });
                        setTotalNumbersProfessional(allTotals);
                    }
                }
                //Por Obra Social
                if (sectionSelected === 1 && dataToRenderSocialWork.length === 0) {
                    setLoadingActive(true);
                    const { data, status } = await GetLiquidationSummaryForSocialWork(id);
                    if (status === StatusCode.Ok) {
                        setDataToRenderSocialWork(data.results);
                        let allTotals: ITotalNumberSocialWork = {
                            totalFactura: 0,
                            totalDebitos: 0,
                        }
                        data.results.forEach(summaryOs => {
                            allTotals.totalFactura += summaryOs.totalFactura;
                            allTotals.totalDebitos += summaryOs.TotalDebito;
                        });
                        setTotalNumbersSocialWork(allTotals);
                    }
                }
            }

        } catch (err: any) {
            MessageNotification(err.message ?? err.response.data.errores, "error");
        } finally {
            setLoadingActive(false);
        }
    }

    async function HandleFinish() {
        try {
            setLoadingActive(true);
            const { status } = await FinalizeLiquidation(id);
            if (status === StatusCode.Ok) {
                disparador(setRestoreAmounts());
                disparador(setDataLiquidation({
                    nombre: liquidacion.nombre,
                    fecha: liquidacion.fecha,
                    estado: StateOfLiquidation.generada
                }));
                window.location.reload();
            }
        } catch (err: any) {
            MessageNotification("error", err?.response?.data?.errores ?? "Ocurrió un error");
        } finally {
            setLoadingActive(false);
        }
    }

    async function HandleViewProfessionalBillsDetails(item: any) {
        try {
            setLoadingActive(true);
            const { data, status } = await GetLiquidationDetailsForProfessional(id, item.profesional.id);
            if (status === StatusCode.Ok) {
                toggleModal();
                setDataToRender(data.results);
            }
        } catch (err: any) {
            const errorMessage = err.message || 'No se pudo obtener los detalles de las facturas del profesional.';
            MessageNotification(errorMessage, "error");
        } finally {
            setLoadingActive(false);
        }
    }
    const toggleModal = () => {
        setShowModalProfessionalBillDetails(!showModalProfessionalBillDetails);
    };

    //#region PDF
    async function HandleExportReportSocialWork() {
        try {
            setLoadingActive(true);
            const { data, status } = await ExportSummarySocialWorksReport(id);
            if (status === StatusCode.Ok || status === StatusCode.NotContent) {
                UtilityFNExport("pdf", "Resumen de Obras Sociales", data.results);
            }
        } catch (err: any) {
            MessageNotification("error", err.response.data.errores);
        } finally {
            setLoadingActive(false);
        }
    }
    async function HandleExportReportProf() {
        try {
            setLoadingActive(true);
            const { data, status } = await ExportSummaryProfessionalReport(id);
            if (status === StatusCode.Ok || status === StatusCode.NotContent) {
                UtilityFNExport("pdf", "Resumen de Profesionales", data.results);
            }
        } catch (err: any) {
            MessageNotification("error", err.response.data.errores);
        } finally {
            setLoadingActive(false);
        }
    }
    async function HandleExportSummaryLiquidation() {
        try {
            setLoadingActive(true);
            const { data, status } = await ExportSummaryLiquidationReport(id);
            if (status === StatusCode.Ok || status === StatusCode.NotContent) {
                UtilityFNExport("pdf", "Resumen de Liquidación", data.results);
            }
        } catch (err: any) {
            MessageNotification("error", err.response.data.errores);
        } finally {
            setLoadingActive(false);
        }
    }
    async function HandleExportSummaryBalance() {
        try {
            setLoadingActive(true);
            const { data, status } = await ExportSummaryBalanceReport(id);
            if (status === StatusCode.Ok || status === StatusCode.NotContent) {
                UtilityFNExport("pdf", "Saldos a Depositar", data.results);
            }
        } catch (err: any) {
            MessageNotification("error", err.response.data.errores);
        } finally {
            setLoadingActive(false);
        }
    }
    async function HandleExportReportSocialWorkPeriod() {
        try {
            setLoadingActive(true);
            const { data, status } = await ExportReportSocialWorkPeriod(id);
            if (status === StatusCode.Ok || status === StatusCode.NotContent) {
                UtilityFNExport("pdf", "Obras Sociales por Periodo", data.results);
            }
        } catch (err: any) {
            MessageNotification("error", err.response.data.errores);
        } finally {
            setLoadingActive(false);
        }
    }
    async function DownloadProfitRetention(item) {
        try {
            setLoadingActive(true);
            const { data, status } = await ExportReportProfitRetention(id, item.profesional.id);
            if (status === StatusCode.Ok || status === StatusCode.NotContent) {
                UtilityFNExport("pdf", "Retención de Ganancias del Profesional", data.results);
            }
        } catch (err: any) {
            const errorMessage = err.message || 'No se pudo obtener el PDF de la Retención';
            MessageNotification(errorMessage, "error");
        } finally {
            setLoadingActive(false);
        }
    }
    //#endregion
    //#region UTILS
    function HandleRestoreValues() {
        history.push("/liquidaciones/lista");
    }
    function UtilityFNExport(extension: string, nameOfFile: string, responseApi: string) {
        let fileName = `${nameOfFile}.${extension}`;
        let blob = new Blob([responseApi], {
            type: "application/pdf",
        });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    function MessageNotification(message: string, type: string) {
        setDataToast({
            active: true,
            message: message || "Acaba de ocurrir un error",
            type
        });
    }
    //#endregion

    return (
        <>
            <div hidden={!loadingActive}>
                <Dimmer className="loader-fixed" active inverted>
                    <Loader size="big">Cargando...</Loader>
                </Dimmer>
            </div>

            <Grid >
                <Grid item style={{ marginBottom: 30 }}>
                    <Card className='color-border-dash'>
                        <CardContent className='center-responsive-filters'>
                            <Grid className='header-section'>
                                <div className="titleAndVideo">
                                    <span style={{ fontSize: 27, textAlign: "left", lineHeight: "1.03" }}>Resumenes</span>
                                </div>
                                <div className="contBtnHeaderSection">
                                    {liquidacion.estado === StateOfLiquidation.borrador &&
                                        <Button onClick={() => HandleFinish()} color='green'>Finalizar</Button>}
                                    {liquidacion.estado === StateOfLiquidation.generada && <>
                                        <Button onClick={HandleExportReportSocialWorkPeriod} color='blue'>Reporte OS Periodo</Button>
                                        <Button onClick={HandleExportSummaryBalance} color='blue'>Reporte Saldos</Button>
                                        <Button onClick={HandleExportSummaryLiquidation} color='blue'>Reporte Liquid.</Button>
                                        <Button onClick={HandleExportReportSocialWork} color='blue'>Reporte Obras S.</Button>
                                        <Button onClick={HandleExportReportProf} color='blue'>Reporte Prof.</Button>
                                    </>}
                                    <Button onClick={HandleRestoreValues} style={{ marginLeft: 10 }}>Salir</Button>
                                </div>
                            </Grid>
                            <Grid className="row-filterList content-filter-list">
                                <TextField
                                    className='filter-input'
                                    style={{ margin: "15px 0" }}
                                    disabled={true}
                                    value={liquidacion.nombre}
                                    label='Nombre'
                                    placeholder="Nombre de liquidación..."
                                    type='text'
                                    variant='outlined'
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                />
                                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        className="size-date-picke-filter filter-input"
                                        style={{ margin: 8 }}
                                        value={new Date(liquidacion.fecha)}
                                        disabled={true}
                                        placeholder="Fecha"
                                        onChange={(date: MaterialUiPickersDate) => { }}
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        inputVariant="outlined"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid style={{ marginTop: 15 }}>
                                {sectionSelected === 0 ?
                                    <>
                                        <section className="summaryTotalAmountsLiquidation--flex">
                                            <p className="summaryTotalAmountsLiquidation--flex__amount">
                                                Total O. Social: <span style={{ color: green[500] }}>{FormatNumber({ style: "currency", currency: "USD", value: totalNumbersProfessional.totalOSocial })}</span>
                                            </p>
                                            <p className="summaryTotalAmountsLiquidation--flex__amount">
                                                Neto a Cobrar (+): <span style={{ color: green[500] }}>{FormatNumber({ style: "currency", currency: "USD", value: totalNumbersProfessional.netoAcobrar })}</span>
                                            </p>
                                            <p className="summaryTotalAmountsLiquidation--flex__amount">
                                                Deudas Canceladas: <span style={{ color: green[500] }}>{FormatNumber({ style: "currency", currency: "USD", value: totalNumbersProfessional.deudasCanceladas })}</span>
                                            </p>
                                        </section>
                                        <section className="summaryTotalAmountsLiquidation--flex">
                                            <p className="summaryTotalAmountsLiquidation--flex__amount">
                                                Total Retenciones: <span style={{ color: red[500] }}>{FormatNumber({ style: "currency", currency: "USD", value: totalNumbersProfessional.totalRetenciones })}</span></p>
                                            <p className="summaryTotalAmountsLiquidation--flex__amount">
                                                Total de Deudas (-): <span style={{ color: red[500] }}>{FormatNumber({ style: "currency", currency: "USD", value: totalNumbersProfessional.totalDeudas })}</span></p>
                                            <p className="summaryTotalAmountsLiquidation--flex__amount">
                                                Total de Saldos (+): <span style={{ color: green[500] }}>{FormatNumber({ style: "currency", currency: "USD", value: totalNumbersProfessional.totalSaldos })}</span></p>
                                        </section>
                                    </>
                                    :
                                    <div className="summaryTotalAmountsLiquidation--flex">
                                        <p className="summaryTotalAmountsLiquidation--flex__amount">
                                            Total Factura: <span style={{ color: green[500] }}>{FormatNumber({ style: "currency", currency: "USD", value: totalNumbersSocialWork.totalFactura })}</span>
                                        </p>
                                        <p className="summaryTotalAmountsLiquidation--flex__amount">
                                            Total Débitos: <span style={{ color: red[500] }}>{FormatNumber({ style: "currency", currency: "USD", value: totalNumbersSocialWork.totalDebitos })}</span>
                                        </p>
                                    </div>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <GeneralModalTabs
                    viewPanelDefault={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    arrPanels={[
                        <SummaryForProfessional
                            DownloadProfitRetention={DownloadProfitRetention}
                            dataToRender={liquidacion.estado === StateOfLiquidation.borrador ? dataToRenderDetails?.resumenProfesionalLiquidacions : dataToRenderProfessional}
                            liquidationStatus={liquidacion.estado}
                            HandleViewProfessionalBillsDetails={HandleViewProfessionalBillsDetails}
                            viewSideBar={viewSideBar}
                        />,
                        <SummaryForSocialWork
                            dataToRender={liquidacion.estado === StateOfLiquidation.borrador ? dataToRenderDetails?.resumenObraSocialLiquidaciones : dataToRenderSocialWork}
                        />
                    ]}
                    arrTittleOfPanels={["Por profesional", "Por obra social"]}
                />

            </Grid>

            {showModalProfessionalBillDetails &&
                <ViewProfesionalBillDetails
                    openModal={showModalProfessionalBillDetails}
                    HandleClose={toggleModal}
                    dataToRender={dataToRender}
                />}
        </>
    );
}

export default SummaryLiquidation;